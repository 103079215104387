import React, {Suspense} from "react";
import {Route, Routes} from "react-router";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Spinner from "./views/Components/Spinner/Spinner";

const Agendamento = React.lazy(() => import('./views/Page/Agendamento/index'));
const Admin = React.lazy(() => import('./views/Page/Admin/index'));

function App() {
  return (
    <div className="App">
        <Suspense fallback={<Spinner/>}>
            <Routes>
                <Route  path="*" element={<Agendamento/>}/>
                <Route  path="/admin/*" element={<Admin/>}/>
            </Routes>
        </Suspense>
    </div>
  );
}

export default App;
